import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import Logo from '../../../assets/logos/postei-logo-color.png'
import { motion } from 'framer-motion'
import { UserService } from '../../../services'

import posteiLogoColor from '../../../assets/logos/postei-logo-color.png'
import posteiLogoTextColor from '../../../assets/logos/postei-logo-text-color.png'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
function StepNotCumputer({ setSubject, subject, setStep }) {
  const { t } = useTranslation()

  const { logout } = useAuth()

  const STEPS = {
    STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
  }

  const handleNavigate = async (type) => {
    console.log('entrou')
    if (type === 'next') {
      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    }
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <div className={styles.page} style={{ overflow: 'hidden' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
            overflowY: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >

          <p
            style={{
              textAlign: 'center',
              fontSize: 'clamp(24px, 6vw, 45px)',
              width: '100%',
              maxWidth: '610px',
              fontWeight: '400',
              color: '#190027',
              margin: '10px 0',
            }}
          >
            Acesso Restrito ao <b style={{color:'#0FBE00'}}>Computador</b>
          </p>

          <p
            style={{
              textAlign: 'center',
              fontSize: 'clamp(16px, 4vw, 22px)',
              width: '100%',
              maxWidth: '610px',
              color: '#190027',
              margin: '10px 0',
            }}
          >
            Para garantir a melhor experiência possível, o acesso ao sistema só é permitido através de um computador desktop, notebook, ou tablet.
          </p>

          <p
            style={{
              textAlign: 'center',
              fontSize: 'clamp(14px, 3.5vw, 18px)',
              width: '100%',
              maxWidth: '610px',
              color: '#190027',
              margin: '10px 0',
            }}
          >
            Por favor, acesse nossa plataforma através de um computador para continuar utilizando todos os recursos disponíveis.
          </p>

          <button
            style={{
              margin: '8px',
              marginTop: '20px',
              padding: '10px 20px',
              borderRadius: 4,
              backgroundColor: '#6A0098',
              fontSize: 'clamp(12px, 3vw, 14px)',
              width: '100%',
              maxWidth: '180px',
            }}
            onClick={handleLogout}
          >
            Sair
          </button>
          {/* <button
            style={{
              margin: '8px',
              padding: '10px 20px',
              borderRadius: 4,
              backgroundColor: '#ffff',
              fontSize: 'clamp(12px, 3vw, 14px)',
              color: '#6A0098',
              border: '1px solid #6A0098',
              width: '100%',
              maxWidth: '180px',
            }}
            onClick={handleLogout}
          >
            Sair
          </button> */}
        </div>
      </div>
    </motion.div>
  )
}

export default StepNotCumputer
