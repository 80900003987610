import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Cross } from '../../assets/svgs/icons'
import imageHelp from '../../assets/imgs/arte.png'
import imageOne from '../../assets/imgs/categoryImageFirst.png'
import imagetwo from '../../assets/imgs/categoryImageTwo.png'
const ModalCategoryHelp = ({
  onClose,
  handleCloseModal,
  isOpen,
  to = 'campaign',
}) => {
  if (!isOpen) return null

  const navigate = useNavigate()
  const { t } = useTranslation()

  // const images = [
  //   {
  //     id: 1,
  //     url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
  //     title: 'Comida saudavel',
  //     size: '32mb',
  //     origin: 'imgBank',
  //   },
  // ]

  const handleClose = async () => {
    handleCloseModal('categoryHelp')
  }

  const handleSuport = async () => {
    if (to === 'campaign') {
      window.open('https://xama.ai/docs/criar-uma-campanha#content', '_blank')
    } else {
      window.open(
        'https://xama.ai/docs/criar-uma-publica%C3%A7%C3%A3o-%C3%BAnica#content',
        '_blank'
      )
    }
    handleCloseModal('categoryHelp')
  }

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        <div className={styles.header_close}>
          <div className={styles.close_modal} onClick={onClose}>
            <Cross width={'20px'} height={'20px'} />
          </div>
        </div>
        <div className={styles.boxTitleSubTitle}>
          <div className={styles.modal_title}>
            <h2 className={styles.txtTitle}>
              {t('modal_help_category.title')}
            </h2>
          </div>

          <div className={styles.modal_sub_title}>
            <p className={styles.modal_sub_title_text}>
              {t('Veja abaixo como categorizar corretamente suas imagens')}
            </p>
          </div>
        </div>

        <div className={styles.box_item_image}>
          <img
            className={styles.imageContainer}
            src={imageHelp}
            // src={'data:image/png;base64,' + image.base64}
            alt=""
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>

        <div className={styles.modal_container_drag}>
          <button
            className={styles.button_back}
            onClick={() => {
              handleSuport()
            }}
          >
            {t('modal_help_category.support')}
          </button>

          <button
            className={styles.button_send}
            onClick={() => {
              handleClose()
            }}
          >
            {t('modal_help_category.understand')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalCategoryHelp
