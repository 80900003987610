import React, { useState } from "react"
import styles from './styles.module.css'


const TooltipComponent = ({infoText, exemplo, children,
    direction = 'bottom' || 'top' || 'left' || 'right',
}) =>{
    const [showTooltip, setShowTooltip] = useState(false)
    return(
    <div className={styles.tooltip_container}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        {children}
    <div className={ showTooltip? styles[`${direction}_tooltip_open`]  : styles.tooltip }>
        {infoText}
        {exemplo ? (<div style={{display:'flex'}}><b>Exemplo:&nbsp;</b>{exemplo}</div>) : ""}
        <div className={styles[`arrow_${direction}`]}></div>
    </div>
    </div>
)
}

export default TooltipComponent