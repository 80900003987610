import Modal from './notificationModal'
import styles from './styles.module.css'
import { useContext, useEffect, useRef, useState } from 'react'

import companyIcon from '../../assets/imgs/enterprise.png'
import logoPostei from '../../assets/logos/postei-logo-color.png'

import {
  User,
  Home,
  ChevronRight,
  Datepiker,
  Bookmark,
  SupermarketCart,
  Target,
  Clock,
  Users,
  UserCircle,
  UserGroup,
  Picture,
  Retangle_group,
  Gear,
  Subtract,
  Sugestions,
} from '../../assets/svgs/icons'
import { BusinessClient, BusinessService, LogosService } from '../../services'
import { useNavigate } from 'react-router-dom'

import { useClient } from '../../hooks/useClient'
import LanguageSwitcher from '../languageSwitcher'
import { useTranslation } from 'react-i18next'
import { getBusinessByClientId } from '../../services/business'
import { useNavigationHook } from '../../hooks/useNavigation'

function Header({ pathname }) {
  const { t } = useTranslation()
  const { clientData, setClientData, allClientData, setAllClientData } =
    useClient()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showAreaClient, setShowAreaClient] = useState(false)

  const areaClientRef = useRef(null)

  const navigate = useNavigate()
  const { updateCampaing, setUpdateCampaing } = useNavigationHook()

  useEffect(() => {
    loadBusinessClient()
    console.log('updateCampaing.', updateCampaing)
  }, [])

  useEffect(() => {
    console.log('updateCampaing.', updateCampaing)
  }, [updateCampaing])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showAreaClient &&
        areaClientRef.current &&
        !areaClientRef.current.contains(event.target) &&
        event.target.getAttribute('data-action') !== 'toggle-area-client'
      ) {
        setShowAreaClient(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showAreaClient])

  const loadLogo = async () => {
    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)

      businessId = data[0]?.uuid

      //console.log('teste 1', data[0])
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid

      //console.log('teste 2', data[0])
    }
    const { data: dataLogo } = await LogosService.getLogosById(businessId)

    const { data: dataLogoBase64 } = await LogosService.getLogosByBase64(
      dataLogo[0].uuid
    )

    return dataLogoBase64
  }

  const loadBusinessClient = async () => {
    //console.log('storage)
    // console.log('storage',JSON.parse(window.localStorage.getItem('userData')))

    const logoBase64 = await loadLogo()

    const storage = JSON.parse(window.localStorage.getItem('clientData'))
    try {
      const businessAccount = await BusinessService.getBusinessByLogin()

      const { data, responseStatus } =
        await BusinessClient.getAllBusinessClient()

      const myAccount = {
        uuid: undefined,
        businessId: businessAccount?.data[0]?.uuid,
        companyName: 'Minha conta',
        BusinessClientPhotoUrl: logoBase64,
      }

      console.log('myAccount', myAccount)

      if (responseStatus === 200) {
        setAllClientData([myAccount, ...data])
      }
      if (storage) {
        const myClient = data.find((obj) => obj.uuid == storage.uuid)

        if (myClient) {
          setClientData(myClient)
        } else {
          setClientData(myAccount)
        }
      } else {
        setClientData(myAccount)
      }
    } catch (error) {
      console.error('Erro ao carregar clientes', error)
    }
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleNavigate = () => {
    navigate('/Clients', {
      state: { header: true },
    })
  }

  //Adiciona componente que mostra o local que o usuário está na aplicação
  console.log('updateCampaing', updateCampaing)
  const ShowPath = () => {
    const patchListRender = [
      {
        icon: <Home color={'#DFDFDF'} />,
        title: t('header.home_title'),
        subtitle: t('header.home_subtitle'),
        path: '/HomePage',
      },
      {
        icon: <Picture color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.publications_title'),
        subtitle: t('header.publications_subtitle'),
        path: '/NewPostPage',
      },
      {
        icon: <Picture color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.publications_title'),
        subtitle: t('header.publications_subtitle_single'),
        path: '/NewPublicationPage',
      },
      {
        icon: <Picture color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.publications_title'),
        subtitle: t('header.publications_subtitle_campaign'),
        path: '/NewPublicationPageCampaign',
      },
      {
        icon: <Picture color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.publications_title'),
        subtitle: t('header.publications_subtitle_my_publication'),
        path: '/NewPublicationPageMyPublication',
      },
      {
        icon: <Picture color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.publications_title'),
        subtitle: t('header.publications_subtitle'),
        path: '/NewPostFormPage',
      },
      {
        icon: <Retangle_group color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.campaigns_title'),
        // subtitle: t('header.campaigns_subtitle'),
        subtitle:
          updateCampaing === 'true' || updateCampaing === true
            ? t('header.campaigns_subtitle_update')
            : t('header.campaigns_subtitle'),
        path: '/Campaign',
      },
      {
        icon: <Retangle_group color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.campaigns_title'),
        subtitle: t('header.campaigns_subtitle'),
        path: '/NewCampaingFormPage',
      },
      {
        icon: <Datepiker color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.calendar_title'),
        subtitle: t('header.calendar_subtitle'),
        path: '/SchedulePublicationPage',
      },
      {
        icon: <Datepiker color={'#CDCDCD'} width={20} height={20} />,
        title: t('header.calendar_title'),
        subtitle: t('header.calendar_subtitle'),
        path: '/SchedulingPage',
      },
      {
        icon: <Bookmark color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.drafts_title'),
        subtitle: t('header.drafts_subtitle'),
        path: '/Draft',
      },
      {
        icon: <SupermarketCart color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.products_title'),
        subtitle: t('header.products_subtitle'),
        path: '/ProductsPage',
      },
      {
        icon: <Target color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.target_audience_title'),
        subtitle: t('header.target_audience_subtitle'),
        path: '/TargetAudience',
      },
      {
        icon: <Clock color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.history_title'),
        subtitle: t('header.history_subtitle'),
        path: '/History',
      },
      {
        icon: <Users color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.clients_title'),
        subtitle: t('header.clients_subtitle'),
        path: '/Clients',
      },
      {
        icon: <UserCircle color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.my_account_title'),
        subtitle: t('header.my_account_subtitle'),
        path: '/MyAccountPage',
      },
      {
        icon: <UserGroup color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.users_title'),
        subtitle: t('header.users_subtitle'),
        path: '/UserList',
      },
      {
        icon: <Subtract color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.settings_title'),
        subtitle: t('header.settings_subtitle'),
        path: '/Settings',
      },
      {
        icon: <Sugestions color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.suggestions_title'),
        subtitle: t('header.suggestions_subtitle'),
        path: '/NewPublicationPage',
      },
      {
        icon: <Sugestions color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: t('header.suggestions_title'),
        subtitle: t('header.suggestions_subtitle'),
        path: '/NewPublicationPageCampaign',
      },
      {
        icon: <Retangle_group color={'#CDCDCD'} width={'20'} height={'20'} />,
        title: 'Mensagens',
        subtitle: 'Conversas',
        path: '/ChatPage',
      },
    ]

    const patchSelected = patchListRender.find(
      (patch) => patch.path === pathname
    )

    if (!patchSelected) return

    return (
      <div className={styles.showPath}>
        {patchSelected.icon && patchSelected.icon}
        <p className={styles.patchTitle}>{patchSelected.title}</p>
        <ChevronRight />
        <p className={styles.patchSubTitle}>{patchSelected.subtitle}</p>
      </div>
    )
  }

  const AreaClient = () => {
    return (
      <div className={styles.area_dropdown} ref={areaClientRef}>
        <p className={styles.title_area}>Selecione um cliente</p>

        <div className={styles.body_area}>
          {allClientData.map((cliente, index) => (
            <div
              key={index}
              className={styles.box_client_option}
              onClick={() => {
                setClientData(cliente)
                setShowAreaClient(false)
              }}
            >
              <div className={styles.box_client_image}>
                <img
                  className={styles.image_client}
                  src={
                    cliente?.BusinessClientPhotoUrl
                      ? `data:image/png;base64,${cliente?.BusinessClientPhotoUrl}`
                      : companyIcon
                  }
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              <div className={styles.slide_container}>
                <p
                  className={
                    cliente?.companyName.length < 28
                      ? styles.cliente_name
                      : styles.cliente_big_name
                  }
                >
                  {cliente?.companyName}
                </p>
              </div>
            </div>
          ))}
        </div>

        <button className={styles.add_cliente} onClick={handleNavigate}>
          <User color={'#3CBC00'} width={'20px'} height={'20px'} /> Adicionar
          Cliente
        </button>
      </div>
    )
  }

  return (
    <header className={styles.headerContainer}>
      <div className={styles.pathContainer}>
        <ShowPath />
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.leftActions}>
          <LanguageSwitcher />
        </div>

        <div className={styles.rightActions}>
          <button
            className={styles.iconButton}
            onClick={() => {
              window.openCXGenieChatWidget()
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.87891 7.51884C11.0505 6.49372 12.95 6.49372 14.1215 7.51884C15.2931 8.54397 15.2931 10.206 14.1215 11.2312C13.9176 11.4096 13.6917 11.5569 13.4513 11.6733C12.7056 12.0341 12.0002 12.6716 12.0002 13.5V14.25M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 17.25H12.0075V17.2575H12V17.25Z"
                stroke="#0F172A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          {/* <button className={styles.iconButton} onClick={openModal}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.99997 2C6.68626 2 3.99997 4.68629 3.99997 8C3.99997 9.88663 3.54624 11.6651 2.7426 13.2343C2.63591 13.4426 2.6326 13.6888 2.73365 13.9C2.83469 14.1111 3.02851 14.2629 3.25769 14.3105C4.32537 14.5322 5.41181 14.7023 6.51426 14.818C6.67494 16.602 8.17421 18 10 18C11.8258 18 13.3251 16.602 13.4857 14.818C14.5882 14.7023 15.6746 14.5322 16.7422 14.3105C16.9714 14.2629 17.1652 14.1111 17.2663 13.9C17.3673 13.6888 17.364 13.4426 17.2573 13.2343C16.4537 11.6651 16 9.88663 16 8C16 4.68629 13.3137 2 9.99997 2ZM8.0493 14.9433C8.69477 14.9809 9.34517 15 9.99997 15C10.6548 15 11.3052 14.9809 11.9507 14.9433C11.749 15.8345 10.9522 16.5 10 16.5C9.04777 16.5 8.25097 15.8345 8.0493 14.9433Z" fill="#190027"/>
            </svg>
          </button> */}

          <button
            data-action="toggle-area-client"
            className={styles.clientButton}
            onClick={() => setShowAreaClient(!showAreaClient)}
          >
            <img
              className={styles.clientImage}
              src={
                clientData?.BusinessClientPhotoUrl
                  ? `data:image/png;base64,${clientData?.BusinessClientPhotoUrl}`
                  : companyIcon
              }
              onContextMenu={(e) => e.preventDefault()}
              data-action="toggle-area-client"
            />
            <span
              className={styles.clientName}
              data-action="toggle-area-client"
            >
              {clientData?.companyName}
            </span>
            <svg
              className={styles.chevronIcon}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              data-action="toggle-area-client"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4419 13.5669C10.1979 13.811 9.80214 13.811 9.55806 13.5669L3.30806 7.31694C3.06398 7.07286 3.06398 6.67714 3.30806 6.43306C3.55214 6.18898 3.94786 6.18898 4.19194 6.43306L10 12.2411L15.8081 6.43306C16.0521 6.18898 16.4479 6.18898 16.6919 6.43306C16.936 6.67714 16.936 7.07286 16.6919 7.31694L10.4419 13.5669Z"
                fill="#b8b8b8"
              />
            </svg>
          </button>
        </div>

        {isModalOpen && (
          <div className={styles.modalContainer}>
            <Modal onClose={closeModal} />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
