import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'
import {
  BoxChecket,
  BoxNotChecket,
  CreditCard,
  CreditCardAmex,
  CreditCardAura,
  CreditCardDiners,
  CreditCardDiscover,
  CreditCardHiperCard,
  CreditCardJbc,
  CreditCardMasterCard,
  CreditCardVisa,
} from '../../../assets/svgs/icons'
import {
  PagarMeService,
  StatesService,
  UserAccountsService,
} from '../../../services'
import ReactInputMask from 'react-input-mask'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'

const PaymentDataArea = ({}) => {
  const { t } = useTranslation()

  const [validateCredCard, setValidateCredCard] = useState('')
  const [cvvCredCard, setCvvCredCard] = useState('')
  const [flagCard, setFlagCard] = useState('')
  const [flagCardLabel, setFlagCardLabel] = useState('')

  const [numberCredCard, setNumberCredCard] = useState('')
  const [numberCredCardValue, setNumberCredCardValue] = useState('')
  const [nameCredCard, setNameCredCard] = useState('')

  const [useUserAddress, setUseUserAddress] = useState(false)
  const [zipCodeCard, setZipCodeCard] = useState('')
  const [logradouroCard, setlogradouroCard] = useState('')
  const [cityCard, setCityCard] = useState('')
  const [stateIdCard, setStateIdCard] = useState('')
  const [stateData, setStateData] = useState([])

  const [countryCard, setCountryCard] = useState(null)

  const [userAcount, setUserAccount] = useState()

  useEffect(() => {
    if (zipCodeCard) {
      const cepModified = zipCodeCard.replace(/\D/g, '')
      if (cepModified.length === 8) getAddress(cepModified)
    }
  }, [zipCodeCard])

  useEffect(() => {
    loadCardData()
    loadData()
  }, [])

  const loadData = async () => {
    let statesReturn = await StatesService.getStates()
    setStateData(statesReturn.data)
  }

  const loadCardData = async () => {
    const dataCard = await PagarMeService.getCardByUser()
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())

    detectCardTypeByFlag(dataCard?.data[0]?.flag)

    if (dataCard.data) {
      setNumberCredCard(dataCard?.data[0]?.number)
      setNumberCredCardValue(dataCard?.data[0]?.number)
      setNameCredCard(dataCard?.data[0]?.cardName)
      setValidateCredCard(dataCard?.data[0]?.expirationDate)
      setZipCodeCard(dataCard?.data[0]?.zipCode)
      setlogradouroCard(dataCard?.data[0]?.logradouro)
      setCityCard(dataCard?.data[0]?.city)
      setStateIdCard(dataCard?.data[0]?.stateId)
      setFlagCardLabel(dataCard?.data[0]?.flag)

      const user = await UserAccountsService.getUserAccountsByLogin()

      if (user.data.cep === dataCard.data[0]?.zipCode) {
        setUseUserAddress(true)
      }
    }
  }

  const getAddress = async (cep) => {
    try {
      setZipCodeCard(cep)
      const url = `https://viacep.com.br/ws/${cep}/json/`
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'content-type': 'application/json;charset=utf-8',
        },
      }
      const result = await fetch(url, options)
      const endereco = await result.json()

      if (!endereco.erro) {
        const end = `${endereco.logradouro}, ${endereco.bairro}`
        setlogradouroCard(end)
        setCityCard(endereco.localidade)
        stateData?.map((item) => {
          if (item.sigla === endereco.uf) setStateIdCard(item.id)
        })
      }
    } catch (e) {
      return null
    }
  }

  const formatCreditCardNumber = (e) => {
    e.preventDefault() // Previne o comportamento padrão
    const inputValue = e.target.value

    // Verifica o tamanho máximo (incluindo espaços)
    if (inputValue.length > 19) {
      return
    }

    // Remove qualquer caractere que não seja número
    const numericValue = inputValue.replace(/\D/g, '')

    // Divide o número em grupos de 4 dígitos
    const formattedValue = numericValue.replace(/(\d{4})/g, '$1 ').trim()

    // Atualiza apenas um estado
    setNumberCredCard(formattedValue)
    setNumberCredCardValue(numericValue) // Se realmente precisar do valor sem formatação
  }

  const formatValidateNumber = (inputValue) => {
    // Remove qualquer caractere que não seja número

    if (inputValue.length === 5) {
      setValidateCredCard(inputValue)
    } else {
      const numericValue = inputValue.replace(/\D/g, '')

      // Limita a 6 dígitos
      const limitedValue = numericValue.slice(0, 4)
      let formattedValue

      if (limitedValue.length >= 2) {
        // Verifica se os dois primeiros números são válidos (entre 1 e 12)
        const firstTwoDigits = parseInt(limitedValue.slice(0, 2), 10)
        if (firstTwoDigits < 1 || firstTwoDigits > 12) {
          // Trunca para dois dígitos válidos
          formattedValue = limitedValue.slice(0, 2)
        }
      }
      // Adiciona a máscara "00/0000"

      if (inputValue.length >= 4) {
        formattedValue = limitedValue
          .replace(/^(\d{2})/, '$1') // Adiciona a barra após os dois primeiros dígitos
          .replace(/(\d{2})(\d{0,2})/, '$1/$2') // Adiciona a barra após os próximos dois dígitos
      }
      // Atualiza o estado com o valor formatado
      setValidateCredCard(formattedValue)
    }
  }

  const formatCcvNumber = (inputValue) => {
    if (inputValue.length > 3) {
      return
    }
    const numericValue = inputValue.replace(/\D/g, '')

    setCvvCredCard(numericValue)
  }

  const cardTypes = [
    { name: 'Visa', value: 'visa', regex: /^4/ },
    { name: 'Mastercard', value: 'mastercard', regex: /^5[1-5]/ },
    { name: 'Amex', value: 'amex', regex: /^3[47]/ },
    { name: 'Diners', value: 'diners', regex: /^3(?:0[0-5]|[68])/ },
    { name: 'Discover', value: 'discover', regex: /^6(?:011|5)/ },
    { name: 'JCB', value: 'jcb', regex: /^(?:2131|1800|35)/ },
    { name: 'Aura', value: 'aura', regex: /^50(?:[0-9]{2})/ },
    { name: 'Hipercard', value: 'hipercard', regex: /^(606282|3841[046])/ },
  ]
  const detectCardType = (number) => {
    const card = cardTypes.find((card) => card.regex.test(number))
    setFlagCard(card ? card.value : '')
    setFlagCardLabel(card ? card.name : '')
  }

  const detectCardTypeByFlag = (flag) => {
    const card = cardTypes.find((card) => card.value === flag)

    setFlagCard(card ? card.value : '')
    setFlagCardLabel(card ? card.name : '')
  }

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '') // Remove caracteres não numéricos

    detectCardType(value)
  }

  const handleInputChange = (e, type) => {
    e.preventDefault()

    if (type === 'credCard') {
      formatCreditCardNumber(e)
      handleChange(e)
    }
    if (type === 'validate') {
      formatValidateNumber(e.target.value)
    }
    if (type === 'cvv') {
      formatCcvNumber(e.target.value)
    }
  }

  function onlyNumbers(input) {
    return input?.replace(/[^0-9]/g, '')
  }

  const validFieldsCard = async (input) => {
    return input.replace(/[^0-9]/g, '')
  }

  const handleSavePaymentData = async () => {
    const data = await PagarMeService.getCardByUser()

    //validFieldsCard()

    const body = {
      uuid: data?.data[0]?.uuid,
      userAccountId: userAcount?.data?.uuid,
      cardToken: `${cvvCredCard}`,
      cardName: `${nameCredCard}/${userAcount?.data?.cnpj}`,
      flag: flagCard,
      number: `${numberCredCardValue}`,
      expirationDate: validateCredCard?.replace(/(\d{2})\/(\d{2})/, '$1/20$2'),
      // expirationDate: validateCredCard,
      type: 'CREDIT',
      logradouro: logradouroCard,
      zipCode: onlyNumbers(zipCodeCard),
      city: cityCard,
      stateId: stateIdCard,
      country: !countryCard ? 'BR' : countryCard,
    }

    if (data.data.length > 0) {
      await PagarMeService.postCard(body)
    } else {
      const { uuid, ...rest } = body

      const { responseStatus } = await PagarMeService.postCard(rest)

      if (responseStatus === 200 || responseStatus === 204) {
        notifySuccessCustom('Cartão alterado com sucesso')
      } else {
        notifyErrorCustom(
          ' Não foi possivel alterar os dados, verifique se os dados estão corretos!'
        )
      }
    }
  }

  const handleEnable = async () => {
    if (useUserAddress) {
      setUseUserAddress(false)
      setCityCard('')
      setStateIdCard('')
      setlogradouroCard('')
      setZipCodeCard('')
    } else {
      setUseUserAddress('true')
      const user = await UserAccountsService.getUserAccountsByLogin()

      if (user) {
        setCityCard(user.data.city)
        setStateIdCard(user.data.state)
        setlogradouroCard(user.data.logradouro)
        setZipCodeCard(user.data.cep)
      }
    }
  }

  return (
    <div className={styles.box_company_card}>
      <div className={styles.box_title_card}>
        <p className={styles.title_card}>{t('settings.payment.title')}</p>

        {/* <div className={styles.box_select}>
            <SelectRound
            htmlFor="select"
            placeholder="Selecione"
            defaultSelected={{
                label: 'Comercial',
                value: '',
            }}
            options={[
                { label: 'Teste', value: 1 },
                { label: 'Teste2', value: 2 },
            ]}
            />
        </div> */}
      </div>

      <div className={styles.container_data_payment}>
        <div>
          <div className={styles.container_cred_card}>
            <div className={styles.content_cred_card}>
              <div>
                <p className={styles.name_cred_card}>{nameCredCard}</p>
              </div>
              <div className={styles.content_separate_cred_card}>
                <p className={styles.number_cred_card}>{numberCredCard}</p>

                <p className={styles.validate_cred_card}>
                  {validateCredCard ? validateCredCard.replace('/20', '/') : ''}
                </p>
              </div>
            </div>

            {/* {
                creditCardRender(flagCardLabel)
            } */}

            {(() => {
              switch (
                ('flagCardLabel',
                flagCardLabel ? flagCardLabel.toLowerCase() : flagCardLabel)
              ) {
                case 'mastercard':
                  return <CreditCardMasterCard />
                case 'visa':
                  return <CreditCardVisa />
                case 'amex':
                  return <CreditCardAmex />
                case 'diners':
                  return <CreditCardDiners />
                case 'discover':
                  return <CreditCardDiscover />
                case 'jbc':
                  return <CreditCardJbc />
                case 'aura':
                  return <CreditCardAura />
                case 'hipercard':
                  return <CreditCardHiperCard />
                default:
                  return <CreditCard />
              }
            })()}
            {/* <CreditCardHiperCard/> */}
          </div>
        </div>

        <div className={styles.container_input}>
          <div className={styles.box_input_separate}>
            <div className={styles.box_input}>
              <label className={styles.input_label}>
                {t('addCard.number')}
              </label>
              <input
                // ref={inputRef}
                className={styles.input_payment}
                placeholder="**** **** **** 4578"
                value={numberCredCard}
                onChange={(e) => handleInputChange(e, 'credCard')}
              />
            </div>
            <div className={styles.box_input}>
              <label className={styles.input_label}>
                {t('settings.payment.card.flag')}
              </label>

              <input
                className={styles.input_payment}
                // placeholder="00/00"
                readOnly={true}
                value={flagCardLabel}
                // onChange={(e) => handleInputChange(e, 'validate')}
              />
            </div>
          </div>

          <div className={styles.box_input}>
            <label className={styles.input_label}>
              {t('settings.payment.card.name')}
            </label>
            <input
              className={styles.input_payment}
              placeholder={t('settings.payment.card.placeholder.name')}
              value={nameCredCard}
              onChange={(e) => setNameCredCard(e.target.value)}
            />
          </div>
          <div className={styles.box_input_separate}>
            <div className={styles.box_input}>
              <label className={styles.input_label}>
                {t('settings.payment.card.expiry')}
              </label>

              <input
                className={styles.input_payment}
                placeholder={t('settings.payment.card.placeholder.expiry')}
                value={
                  validateCredCard?.length >= 5
                    ? validateCredCard.replace('/20', '/')
                    : validateCredCard
                }
                // value={validateCredCard}
                onChange={(e) => handleInputChange(e, 'validate')}
              />
            </div>
            <div className={styles.box_input}>
              <label className={styles.input_label}>
                {' '}
                {t('settings.payment.card.cvv')}
              </label>
              <input
                className={styles.input_payment}
                placeholder={t('settings.payment.card.placeholder.cvv')}
                value={cvvCredCard}
                onChange={(e) => handleInputChange(e, 'cvv')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.box_check}>
        <div onClick={handleEnable} className={styles.input_check}>
          {useUserAddress ? (
            <BoxChecket />
          ) : (
            <BoxNotChecket borderColor={'#190027'} />
          )}
        </div>
        <p>{t('Utilizar o endereço de cadastro?')}</p>
      </div>
      <div className={styles.box_input_separate}>
        <div className={styles.box_input}>
          <label className={styles.input_label}>{t('CEP')}</label>
          <ReactInputMask
            className={styles.input_payment}
            placeholder={t('Digite o CEP')}
            mask="99999-999"
            value={zipCodeCard}
            disabled={useUserAddress}
            onChange={(e) => setZipCodeCard(e.target.value)}
          />
        </div>

        <div className={styles.box_input}>
          <label className={styles.input_label}>{t('Logradouro')}</label>
          <input
            disabled={useUserAddress}
            className={styles.input_payment}
            placeholder={t('Digite o logradouro')}
            value={logradouroCard}
            onChange={(e) => setlogradouroCard(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.box_input_separate}>
        <div className={styles.box_input}>
          <label className={styles.input_label}>{t('Cidade')}</label>

          <input
            disabled={useUserAddress}
            className={styles.input_payment}
            placeholder="Digite a cidade"
            value={cityCard}
            onChange={(e) => setCityCard(e.target.value)}
          />
        </div>
        <div className={styles.box_input}>
          <label className={styles.input_label}>{t('Estado')}</label>

          <select
            className={styles.input_payment}
            disabled={useUserAddress}
            // onChange={handleChangeState}
            onChange={(e) => setStateIdCard(e.target.value)}
            value={stateIdCard}
          >
            <option value="">Estado</option>
            {stateData?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* <div>
        <button
            className={styles.button_save_payment_data}
            onClick={() => handleSavePaymentData()}
        >
            {t('settings.payment.save')}
                </button>
            </div> */}

      <div>
        <button
          className={styles.button_save_payment_data}
          onClick={() => handleSavePaymentData()}
        >
          {t('settings.payment.save')}
        </button>
      </div>
    </div>
  )
}

export default PaymentDataArea
