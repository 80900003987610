import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const postLogo = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}logos`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postLogos', error.response.data)
      return error
    })
}

export const putLogos = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}logos/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error putLogos', error.response.data)
      return error
    })
}

export const getLogosByBusinessClientId = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}logos-business-client-id/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLogosByBusinessClientId', error.response.data)
      return error
    })
}

export const getLogosById = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}logos-business-id/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getProductById', error.response.data)
      return error
    })
}

export const getLogosByCampaign = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}logos-by-campaign/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLogosByCampaign', error.response.data)
      return error
    })
}

export const getLogosByBase64 = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}logos-by-base64/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLogosByBase64', error.response.data)
      return error
    })
}

export const deleteLogo = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}logos/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllLogo', error.response.data)
      return error
    })
}

export const getLogosUserBase64 = async (moreConfig) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
    ...moreConfig
  }

  return await axios
    .get(`${BASE_URL}logos-all-data-base64`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLogosUserBase64', error.response.data)
      return error
    })
}

export const getLogosByClientBase64 = async (clientId) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}logos-business-client-id-base64/${clientId}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLogosByClientBase64', error.response.data)
      return error
    })
}

export const getLogosByCampaignBase64 = async (campaignId) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}logos-by-campaign-base64/${campaignId}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getLogosByCampaignBase64', error.response.data)
      return error
    })
}
