/* eslint-disable react/prop-types */
import { useState } from 'react'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Cross } from '../../assets/svgs/icons'
const ModalWarninFreepik = ({
  onClose,
  handleCloseModal,
  isOpen,
  type = '',
}) => {
  if (!isOpen) return null

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleDowngrade = async () => {
    handleCloseModal(false)
  }

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        <div className={styles.header_close}>
          <div className={styles.close_modal} onClick={onClose}>
            <Cross width={'20px'} height={'20px'} />
          </div>
        </div>

        <div className={styles.modal_title}>
          <h2>{t('modal_error_freepik.title')}</h2>
        </div>

        <div className={styles.modal_sub_title}>
          {type === 'ia' ? (
            <p className={styles.modal_sub_title_text}>
              {t('modal_error_freepik.description.ia')}
            </p>
          ) : (
            <p className={styles.modal_sub_title_text}>
              {t('modal_error_freepik.description.image_bank')}
            </p>
          )}
        </div>

        {/* <div className={styles.modal_container_drag}>
          <button className={styles.button_back} onClick={onClose}>
            {t('modal_downgrade.back')}
          </button>

          <button
            className={styles.button_send}
            onClick={() => {
              setLoading(true)
              handleDowngrade()
            }}
          >
            {t('modal_downgrade.continue')}
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default ModalWarninFreepik
