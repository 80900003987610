import styles from './styles.module.css'
import {
  Accept,
  CalendarIcon,
  Clock,
  Facebook,
  FacebookSquare,
  Instagram,
  Linkedin,
  //Pinterest,
  Reject,
  TikTok,
  Twitter,
  XTwitter,
} from '../../assets/svgs/icons'
import CarouselSchedule from '../CarouselSchedule'
import PropTypes from 'prop-types'
import { LinkedinService, PublicationService } from '../../services'
import { BusinessService } from '../../services/'
// import { useLocation } from 'react-router-dom'
import { notifyErrorCustom, notifySuccessCustom } from '../../utils/notify'
import { useClient } from '../../hooks/useClient'
import { getBusinessByClientId } from '../../services/business'

import dayjs from 'dayjs'
// import { Loading } from '../Loading'

import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

export const DisplaySchedule = ({
  selectObjectCalendar,
  loadData,
  setLoading,
}) => {
  const { t } = useTranslation()

  const { clientData } = useClient()

  const [isEditing, setIsEditing] = useState(false)
  const [editValue, setEditValue] = useState('')

  useEffect(() => {
    console.log('selectObjectCalendar', selectObjectCalendar)
    console.log('loadData', loadData)
  }, [selectObjectCalendar])

  const ImmediatePublication = async (selectObjectCalendar) => {
    let businessId
    let jobId
    let ImageName

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }

    const { data } = await PublicationService.getPublication(
      selectObjectCalendar?.id
    )

    jobId = data?.[0].jobId
    ImageName = data?.[0].publicationImageUrlName
    const publicationMode = data?.[0].publicationMode

    // Converte a data e hora do formato brasileiro para o formato do dayjs
    const [day, , month] = selectObjectCalendar.date.split(' ')
    const monthNumber = {
      'janeiro': 0, 'fevereiro': 1, 'março': 2, 'abril': 3,
      'maio': 4, 'junho': 5, 'julho': 6, 'agosto': 7,
      'setembro': 8, 'outubro': 9, 'novembro': 10, 'dezembro': 11
    }[month.toLowerCase()]

    const [hours, minutes] = selectObjectCalendar.hour.split(':')
    const scheduleDateTime = dayjs()
      .month(monthNumber)
      .date(parseInt(day))
      .hour(parseInt(hours))
      .minute(parseInt(minutes))

    const publicationBody = {
      headline: selectObjectCalendar?.description,
      legend: selectObjectCalendar?.copy,
      sketch: false,
      publicationImageUrlName: ImageName,
      publicationImageUrl: selectObjectCalendar?.images[0]?.url,
      status: 'Publicado',
      isCanceled: false,
      scheduleDate: scheduleDateTime,
      publicationDate: scheduleDateTime,
      jobId: jobId,
      guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId,
      publicationMode: publicationMode,
    }

    const publicationResponse = await PublicationService.putPublication(
      selectObjectCalendar?.id,
      businessId,
      selectObjectCalendar?.socialTypes,
      publicationBody
    )

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom(t('displaySchedule.successPublication'))

      loadData(
        new Date().getMonth(),
        new Date().getFullYear(),
        formatDate(new Date()),
        undefined,
        selectObjectCalendar?.id
      )
    } else {
      notifyErrorCustom(t('displaySchedule.errorPublication'))
    }

    setLoading(false)
  }

  const cancelPublication = async (selectObjectCalendar) => {
    let businessId
    let jobId
    let ImageName

    const { data } = await PublicationService.getPublication(
      selectObjectCalendar?.id
    )

    jobId = data?.[0].jobId
    ImageName = data?.[0].publicationImageUrlName
    const publicationMode = data?.[0].publicationMode

    const dateNow = new Date()
    const today = new Date(dateNow.getTime() - 3 * 60 * 60 * 1000)

    const publicationBody = {
      headline: selectObjectCalendar?.description,
      legend: selectObjectCalendar?.copy,
      sketch: false,
      publicationImageUrlName: ImageName,
      publicationImageUrl: selectObjectCalendar?.images[0]?.url,
      status: 'Cancelado',
      isCanceled: true,
      scheduleDate: selectObjectCalendar.scheduleDate,
      publicationDate: selectObjectCalendar.publicationDate,
      jobId: jobId,
      guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId,
      publicationMode: publicationMode,
    }

    const publicationResponse = await PublicationService.cancelPublication(
      selectObjectCalendar?.id,
      // businessId,
      // selectObjectCalendar?.socialTypes,
      publicationBody
    )

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom(t('displaySchedule.successCancel'))

      loadData(
        new Date().getMonth(),
        new Date().getFullYear(),
        formatDate(new Date()),
        0
      )
    } else {
      notifyErrorCustom(t('displaySchedule.errorCancel'))
    }
    //}
  }

  const cancelPublicationMovie = async (selectObjectCalendar) => {
    let publicationResponse =
      await PublicationService.putCancelPublicationsMovie(
        selectObjectCalendar.id
      )

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom(t('displaySchedule.successCancel'))

      loadData(
        new Date().getMonth(),
        new Date().getFullYear(),
        formatDate(new Date()),
        0
      )
    } else {
      notifyErrorCustom(t('displaySchedule.errorCancel'))
    }
  }

  const ImmediatePublicationMovie = async (selectObjectCalendar) => {
    let businessId
    let jobId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }

    const { data } = await PublicationService.getPublicationMovie(
      selectObjectCalendar?.id
    )

    jobId = data?.[0].jobId
    const publicationMode = data?.[0].publicationMode

    const publicationBody = {
      status: 'Publicado',
      scheduleDate: dayjs(),
      publicationDate: dayjs(),
      jobId: jobId,
      guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId,
      publicationMode: publicationMode,
      publicationMovieUrl: data?.[0].publicationMovieUrl,
      legend: data?.[0].legend,
    }

    const publicationResponse = await PublicationService.putPublicationMovie(
      selectObjectCalendar?.id,
      businessId,
      selectObjectCalendar?.socialTypes,
      publicationBody
    )

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom(t('displaySchedule.successPublication'))

      loadData(
        new Date().getMonth(),
        new Date().getFullYear(),
        formatDate(new Date()),
        undefined,
        selectObjectCalendar?.id
      )
    } else {
      notifyErrorCustom(t('displaySchedule.errorPublication'))
    }

    setLoading(false)
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const day = date.getDate() // Obtém o dia
    const month = (date.getMonth() + 1).toString().padStart() // Os meses são indexados a partir de zero
    const year = date.getFullYear().toString()
    return `${month}-${day}-${year}`
  }

  const socialMediaVerification = (socialTypes = '') => {
    const socialTypesArray = socialTypes ? socialTypes.split(',') : []

    // Mapeia cada tipo para o componente correspondente
    const components = socialTypesArray.map((type) => {
      if (type === '1') {
        return (
          <Linkedin
            key="linkedin" // Adicione uma chave única
            width={'40px'}
            height={'40px'}
            color={'#FF8B00'}
          />
        )
      }
      if (type === '2') {
        return (
          <XTwitter
            key="twitter" // Adicione uma chave única
            width={'40px'}
            height={'40px'}
            color={'#FF8B00'}
          />
        )
      }
      if (type === '3') {
        return (
          <TikTok
            key="tiktok" // Adicione uma chave única
            width={'40px'}
            height={'40px'}
            color={'#FF8B00'}
          />
        )
      }
      if (type === '4') {
        return (
          <Instagram
            key="instagram" // Adicione uma chave única
            width={'40px'}
            height={'40px'}
            color={'#FF8B00'}
          />
        )
      }
      if (type === '5') {
        return (
          <Facebook
            key="facebook" // Adicione uma chave única
            width={'40px'}
            height={'40px'}
            color={'#FF8B00'}
          />
        )
      }
      return null // Retorna null para tipos desconhecidos
    })

    return components // Retorna o array de componentes
  }

  const handleSave = async () => {

    let businessId
    let jobId
    let ImageName

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }

    const { data } = await PublicationService.getPublication(
      selectObjectCalendar?.id
    )

    jobId = data?.[0].jobId
    ImageName = data?.[0].publicationImageUrlName
    const publicationMode = data?.[0].publicationMode

    console.log('selectObjectCalendar', selectObjectCalendar)

    // Converte a data e hora do formato brasileiro para o formato do dayjs
    const [day, , month] = selectObjectCalendar.date.split(' ')
    const monthNumber = {
      'janeiro': 0, 'fevereiro': 1, 'março': 2, 'abril': 3,
      'maio': 4, 'junho': 5, 'julho': 6, 'agosto': 7,
      'setembro': 8, 'outubro': 9, 'novembro': 10, 'dezembro': 11
    }[month.toLowerCase()]

    const [hours, minutes] = selectObjectCalendar.hour.split(':')
    const scheduleDateTime = dayjs()
      .month(monthNumber)
      .date(parseInt(day))
      .hour(parseInt(hours))
      .minute(parseInt(minutes))

    const publicationBody = {
      headline: selectObjectCalendar?.description,
      legend: editValue,
      sketch: false,
      publicationImageUrlName: ImageName,
      publicationImageUrl: selectObjectCalendar?.images[0]?.url,
      status: selectObjectCalendar?.status,
      isCanceled: false,
      scheduleDate: scheduleDateTime,
      publicationDate: scheduleDateTime,
      jobId: jobId,
      guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId,
      publicationMode: publicationMode,
    }

    const publicationResponse = await PublicationService.putPublication(
      selectObjectCalendar?.id,
      businessId,
      selectObjectCalendar?.socialTypes,
      publicationBody
    )

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom("Publicação atualizada com sucesso")

      loadData(
        new Date().getMonth(),
        new Date().getFullYear(),
        formatDate(new Date()),
        undefined,
        selectObjectCalendar?.id
      )

      setIsEditing(false)
    } else {
      notifyErrorCustom(t('displaySchedule.errorPublication'))
    }

    setLoading(false)
  }

  return (
    <div className={styles.container_publication}>
      <div className={styles.publication_card}>
        <div className={styles.publication_card_header}>
          <div>
            <p className={styles.publication_card_header_title}>
              {t('displaySchedule.publication')}
            </p>
          </div>
          {selectObjectCalendar?.status != 'Publicado' &&
            selectObjectCalendar?.status != 'Cancelado' && (
              <div className={styles.box_button_publication}>
                <button
                  className={styles.button_cancel}
                  onClick={() => {
                    if (selectObjectCalendar?.videoUrl) {
                      cancelPublicationMovie(selectObjectCalendar)
                      return
                    }

                    cancelPublication(selectObjectCalendar)
                  }}
                >
                  <p className={styles.button_cancel_title}>
                    {t('displaySchedule.cancel')}
                  </p>

                  <Reject width={'15px'} height={'15px'} />
                </button>
                <button
                  className={
                    selectObjectCalendar.status === 'Cancelado'
                      ? styles.button_publish_disabled
                      : styles.button_publish
                  }
                  onClick={() => {
                    if (selectObjectCalendar?.videoUrl) {
                      setLoading(true)
                      ImmediatePublicationMovie(selectObjectCalendar)
                      return
                    }
                    setLoading(true)
                    ImmediatePublication(selectObjectCalendar)
                  }}
                >
                  <p className={styles.button_publish_title}>
                    {t('displaySchedule.publishImmediately')}
                  </p>
                  <Accept width={'12px'} height={'12px'} />
                </button>
              </div>
            )}
        </div>

        <div className={styles.publication_card_body}>
          <div className={styles.publication_description}>
            <p className={styles.publication_description_title}>
              {selectObjectCalendar.description}
            </p>
            <div
              className={styles.publication_status}
              style={{
                background:
                  selectObjectCalendar.status === 'Agendado'
                    ? '#FF8B001A'
                    : selectObjectCalendar.status === 'Publicado'
                      ? '#3CBC001A'
                      : '#BC00001A',
              }}
            >
              <Clock
                width={'15px'}
                height={'15px'}
                color={
                  selectObjectCalendar.status === 'Agendado'
                    ? '#FF8B00'
                    : selectObjectCalendar.status === 'Publicado'
                      ? '#3CBC00'
                      : '#FF2F00'
                }
              />
              <p
                className={styles.publication_status_title}
                style={{
                  color:
                    selectObjectCalendar.status === 'Agendado'
                      ? '#FF8B00'
                      : selectObjectCalendar.status === 'Publicado'
                        ? '#3CBC00'
                        : '#FF2F00',
                }}
              >
                {selectObjectCalendar.status}
              </p>
            </div>
          </div>

          <div className={styles.publication_social_media}>
            {socialMediaVerification(selectObjectCalendar?.socialTypes)}
            {/* <Pinterest
              width={'40px'}
              height={'40px'}
              color={selectObjectCalendar.pinterest ? '#FF8B00' : ''}
            />
            <Linkedin
              width={'40px'}
              height={'40px'}
              color={selectObjectCalendar.linkedin ? '#FF8B00' : ''}
            />
            <FacebookSquare
              width={'40px'}
              height={'40px'}
              color={selectObjectCalendar.facebook ? '#FF8B00' : ''}
            />
            <Instagram
              width={'40px'}
              height={'40px'}
              color={selectObjectCalendar.instagram ? '#FF8B00' : ''}
            />
            <TikTok
              width={'40px'}
              height={'40px'}
              color={selectObjectCalendar.tiktok ? '#FF8B00' : ''}
            /> */}
          </div>
          <div className={styles.publication_date_hour_media}>
            <div className={styles.date_media}>
              <CalendarIcon width={'20px'} height={'20px'} />
              <div>
                <p className={styles.date_media_label}>
                  {t('displaySchedule.date')}
                </p>
                <p className={styles.date_media_title}>
                  {selectObjectCalendar.date}
                </p>
              </div>
            </div>
            <div className={styles.hour_media}>
              <Clock width={'20px'} height={'20px'} />
              <div>
                <p className={styles.hour_media_label}>
                  {t('displaySchedule.hour')}
                </p>
                <p className={styles.hour_media_title}>
                  {selectObjectCalendar.hour}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.showcase}>
            <div className={styles.caroussel_showcase}>
              {selectObjectCalendar?.videoUrl && (
                <>
                  <ReactPlayer
                    style={{ marginTop: 20 }}
                    url={selectObjectCalendar?.videoUrl}
                    controls
                    width="100%"
                    height={200}
                  // light={posterUrl}
                  />
                </>
              )}
              {!selectObjectCalendar?.videoUrl && (
                <CarouselSchedule
                  items={selectObjectCalendar}
                  type={'scroll'}
                />
              )}
            </div>
          </div>

          <div className={styles.container_description}>
            <div className={styles.copy}>
              <p className={styles.copy_title}></p>
              {isEditing ? (
                <div className={styles.edit_mode}>
                  <textarea
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    className={styles.copy_input}
                    style={{
                      border: '1px solid rgb(237, 237, 237);',
                      background: '#f5f5f5',
                      fontFamily: 'Poppins',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      marginTop: '10px',
                      width: '100%',
                      height: '150px',

                      resize: 'none',
                      fontSize: '14px',
                    }}
                  />
                  <div className={styles.edit_buttons} style={{ display: 'flex', gap: '10px' }}>
                    <button 
                      // onClick={handleSave}
                      onClick={() => {
                        setLoading(true)
                        handleSave()
                      }}
                      style={{
                        background: '#00b828',
                        color: '#FFFFFF',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px',
                        height: '40px',
                      }}>Salvar</button>

                    <button onClick={() => setIsEditing(false)}
                      style={{
                        background: '#ff2f00',
                        color: '#FFFFFF',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px',
                        height: '40px',
                      }}>Cancelar</button>
                  </div>
                </div>
              ) : (
                <div className={styles.view_mode}>
                  <p className={styles.copy_description}>
                    {selectObjectCalendar.copy}
                  </p>
                  {selectObjectCalendar?.status != 'Publicado' &&
                    selectObjectCalendar?.status != 'Cancelado' && (<button onClick={() => {
                      setIsEditing(true);
                      setEditValue(selectObjectCalendar.copy);
                    }}
                      style={{
                        background: '#FF8B00',
                        color: '#FFFFFF',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                    >
                      Editar
                    </button>)}
                </div>
              )}
            </div>

            <div className={styles.objective}>
              <p className={styles.objective_title}></p>
              <p className={styles.objective_description}>
                {selectObjectCalendar.objective}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.publication_card_botton}>
          <div className={styles.container_button_awaiting_publication}>
            {selectObjectCalendar?.status != 'Publicado' && (
              <button className={styles.button_awaiting_publication}>
                <p className={styles.button_awaiting_publication_title}>
                  {t('displaySchedule.awaitingPublication')}
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

DisplaySchedule.propTypes = {
  selectObjectCalendar: PropTypes.func,
}
