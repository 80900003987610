import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const postBusiness = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}business`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postBusiness', error.response.data)
      return error
    })
}

export const getBusinessByClientId = async (id, moreConfig) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
    ...moreConfig,
  }

  return await axios
    .get(`${BASE_URL}business-by-client/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postBusiness', error.response.data)
      return error
    })
}

export const getBusinessByLogin = async (moreConfig) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
    ...moreConfig,
  }

  return await axios
    .get(`${BASE_URL}business-by-login`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postBusiness', error.response.data)
      return error
    })
}

// export const getBusinessByCampaign = async () => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}business-by-login`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error postBusiness', error.response.data)
//       return error
//     })
// }

export const putBusiness = async (uuid, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}business/${uuid}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error putBusiness', error.response.data)
      return error
    })
}

export const getBusinessByCampaign = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}business-by-campaign/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getBusinessByCampaign', error.response.data)
      return error
    })
}

export const deleteBusinessByClientByUser = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}business-delete-user-by-client`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error deleteBusinessClientByUser', error.response.data)
      return error
    })
}
